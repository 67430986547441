import styled from 'styled-components'
import { customMedia } from '../common/customMedia'

export const Container = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 160px;
  `}
`

export const Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 900px;
  `}

  margin: 0 auto;
`

export const Header = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
  `}
`

export const Title = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 20px 0;
      
      div {
        :nth-child(1) {
          font-size: 2rem;
        }
        :nth-child(2) {
          font-size: 1rem;
          margin: 10px 0;
        }
      }
  `}
  ${customMedia.greaterThan("medium")`
    margin: 60px 0;
    
    div {
      :nth-child(1) {
        font-size: 4rem;
      }
      :nth-child(2) {
        font-size: 2rem;
        margin: 20px 0;
      }
    }
  `}

  text-align: center;
`

export const Introduction = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 20px;
    
    div {
      margin: 10px 0;
    }
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 38px;
    
    div {
      margin: 20px 0;
    }
  `}
`

export const Article = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 20px;
    margin: 20px 0 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 38px;
    margin: 40px 0 100px;
  `}

  display: -webkit-flex;
  display: flex;
  width: 100%;
`

export const ArticleTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    margin-right: 8px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.4rem;
    margin-right: 16px;
  `}

  font-weight: bold;
  width: 20%;
`

export const ArticleContent = styled.div`
  ${customMedia.lessThan("medium")`
    div, ul {
      margin: 0 0 20px;
    }

    ul {
      margin-left: 40px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    div, ul {
      margin: 0 0 40px;
    }

    ul {
      margin-left: 80px;
    }
  `}

  width: 80%;

`