import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { Law } from '../components/Docs/Law'

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title=''
        description=''
        keywords={[]}
      />
      <H1></H1>
      <Law />
    </Layout>
  )
}

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage
