import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'

import { Container, Wrapper, Header, Title, Introduction } from '..'

export const Law = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <Header>
            <div>INSPIRATION CULT MARKET</div>
            <div>特商法</div>
          </Header>

          <Title>
            <div>Act on Specified Commercial Transactions</div>
            <div>特商法に基づく表記</div>
          </Title>

          <Introduction>
            「特定商取引に関する法律」第11条（通信販売についての広告）に基づき、以下に明⽰いたします。
          </Introduction>


          <Table>
            <Tr>
              <Td>販売業者</Td>
              <Td>株式会社ウィットプラーツ</Td>
            </Tr>

            <Tr>
              <Td>業務内容</Td>
              <Td>プロジェクトデザイン／マーケティングデザイン／ブランディングデザイン</Td>
            </Tr>

            <Tr>
              <Td>代表者</Td>
              <Td>原⽥康平</Td>
            </Tr>

            <Tr>
              <Td>サイト</Td>
              <Td>https://inspirationcultmarket.com</Td>
            </Tr>

            <Tr>
              <Td>運営統括責任者</Td>
              <Td>原⽥康平</Td>
            </Tr>

            <Tr>
              <Td>所在地</Td>
              <Td>東京都⽬⿊区⾃由が丘3丁⽬7番10号</Td>
            </Tr>

            <Tr>
              <Td>連絡先</Td>
              <Td>03-6805-0205</Td>
            </Tr>

            <Tr>
              <Td>メールアドレス</Td>
              <Td>info@inspirationcultmarket.com</Td>
            </Tr>

            <Tr>
              <Td>御注⽂⽅法</Td>
              <Td>WEBフォーム</Td>
            </Tr>

            <Tr>
              <Td>販売価格</Td>
              <Td>サイト内に表⽰されている価格となります。尚、原則的に⼀度購⼊された商品についてはお客様のご都合によるキャンセル等は承ることは出来ません。</Td>
            </Tr>

            <Tr>
              <Td>商品以外の必要な⾦額</Td>
              <Td>
                【消費税】10%<br />
                【送料】商品によってことなりますので、ご購⼊時にご確認ください
              </Td>
            </Tr>

            <Tr>
              <Td>お⽀払い⽅法</Td>
              <Td>
                ご利⽤可能な⽀払い⽅法<br />
                クレジットカード決済（VISA/MASTER/JCB/AmericanExpress）
              </Td>
            </Tr>

            <Tr>
              <Td>お⽀払い期限</Td>
              <Td>
                ご利⽤のクレジットカードの締め⽇や契約内容により異なります。ご利⽤されるカード会社までお問い合わせください。<br />
                購⼊可能な範囲の制限⼀部の国や地域を除き海外への発送も対応いたします。<br />
                商品のお渡し時期⼊⾦の確認が取れ次第順次発送いたします。尚、商品によって配送期間は変わりますのでご了承ください。<br />
              </Td>
            </Tr>

            <Tr>
              <Td>不良品の返品</Td>
              <Td>
                「不良品」、「ご注⽂内容と異なる」商品が到着した場合、未使⽤の場合に限り、返品対応をさせていただきます。<br />
                ご返送いただいた商品の対応については、不良部分を確認次第、メールにて連絡をいたします。送料は当社負担にて対応致します。<br />
                また、以下の場合の返品はお受け致しかねます。<br />
                1. 到着から8⽇以上経過した商品<br />
                2. 使⽤済みあるいは洗濯後の商品<br />
                3. 納品書、タグを紛失された商品<br />
                4. お客様のもとで汚れ、キズが⽣じた商品<br />
                5. 下着等の衛⽣商品<br />
                6. ご返送時の商品状態がお届け時と⼤きく異なっていた場合（箱・付属品も含む）<br />
              </Td>
            </Tr>
          </Table>
          
        </Wrapper>
      </Container>
    </>
  )
}

const Table = styled.table`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    margin: 40px 0;
  `}

  height: 100vh;
`

const Tr = styled.tr`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Td = styled.td`
  ${customMedia.lessThan("medium")` 
    :nth-child(1) {
      font-weight: bold;
      width: 120px;
    }

    line-height: 2  0px;
    padding: 10px 0;
  `}
  ${customMedia.greaterThan("medium")`
    :nth-child(1) {
      font-weight: bold;
      width: 220px;
    }

    line-height: 30px;
    padding: 20px 0;
  `}
`

